.section {
    position: relative;
    width: 100%;
    //height: 2700px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 8%;
}

.headtext {
    position: relative;
    width: 40%;
    height: auto;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.characterContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 62%;
    height: 20%;
    margin-top: 3%;
}

.character {
    width: 20%;
    position: relative;
}

// Progress bar
.progressContainer {
    width: 95%;
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

}

.progressCompleteContainer {
    width: 60%;
    height: 10%;
    margin-top: 10%;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1%;
}

.reward {
    width: 20%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rewardCheck{
    width: 65%;
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
}

.hidden{
    display: none;
}

.rewardName {
    color: white;
    font-size: 1.7rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    font-family: 'Noto Sans Thai';
    font-weight: 700;
    align-items: center;
    white-space: nowrap;
    text-align: center;
    margin-top: 10%;
}

.indexedStep {
    color: white;
    width: 1.3rem;
    height: 1.3rem;
    background-color: rgba(211, 211, 211, 1);
    outline: 1px solid #AA9D69;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.indexedStep.accomplished {
    background-color: #8C2C23;
}

.label {
    color: white;
    font-size: 1.7rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    font-family: 'Noto Sans Thai';
    font-weight: 700;
    align-items: center;
    white-space: nowrap;
    text-align: center;
    transform: translateY(-100%);
}

// -- Follow reward
.followRewardContainer{
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 59%;
    height: 15%;
}

//-- Button
.btnContainer{
    width: 59%;
    height: 3.5%;
    margin-top: 2%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}