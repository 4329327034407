.section {
    position: relative;
    width: 100%;
    //height: 2700px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 8%;
}

.headtext {
    position: relative;
    width: 40%;
    height: auto;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.characterShowcaseContainer {
    height: 75%;
    width: 70%;
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1%;
}

.characterSelection {
    height: 100%;
    width: 13%;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.characterImage {
    height: 110%;
}

.characterSummaryContainer {
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.header {
    display: flex;
    align-items: center;
    height: 6rem;
    font-family: 'Noto Sans Thai';
    font-weight: 600;
    color: white;
    white-space: nowrap;
    font-size: 5rem;
    background: linear-gradient(to right, #F4D9A4 0%, #FAEED5 50%, #FFFEFD 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

}

.flag {
    height: 100%;
}

.subHeader {
    width: 85%;
    margin-top: 3%;
    height: 6.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Noto Serif Thai', serif;
    font-weight: 400;
    color: white;
    white-space: nowrap;
    font-size: 3.2rem;
    font-optical-sizing: auto;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.summaryContent {
    margin-top: 5%;
    margin-bottom: 5%;
    width: 90%;
    display: flex;
    position: relative;
    flex-direction: row;
    font-family: 'Noto Serif Thai', serif;
    font-weight: 300;
    color: white;
    font-size: 2rem;
    line-height: 3rem;
    text-align: left;
    align-items: flex-start;
}