.carouselContainer {
    width: 80%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 38px;
    background-image: url('https://sko-cdn.zoltangames.com/preregister/bg-system-info-carousel.png');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.overlayBackground {
    position: fixed;
    overflow: hidden;
    transition: transform 0.1s, background-color 0.1s;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    top: 0;
    left: 0;
    visibility: hidden;
    z-index: 100;
    cursor: pointer;
  }
.overlayBackground.show {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.7); 
  }

.popUpContent{
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.textContainer{
    width: 90%;
    height: 40%;
    margin-top: 3%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.headerText{
    height: 20%;
}

.subHeaderText{
    color: white;
    font-family: 'Noto Sans Thai', serif;
    font-weight: 900;
    color: white;
    font-size: 2rem;
    text-align: center;
    text-align: center;
}

.infoText{
    color: white;
    margin-top: 1%;
    width: 90%;
    font-family: 'Noto Sans Thai', serif;
    font-weight: 900;
    color: white;
    font-size: 1.8rem;
    line-height: 3rem;
    text-align: center;
    text-align: center;
}