.footer {
  width: 100%;
  height: 30%;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footerIconGroup {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0.4%;
}

.footerIcon {
  display: inline-block;
  width: 1.375rem;
  margin: 0 1rem;
}

.footerLogo {
  height: 17.6%;
  margin: 0.4%;
}

.footerCopyright {
  font-family: 'Noto Sans Thai';
  font-size: 1.75rem;
  font-weight: 900;
  line-height: 2.64rem;
  color: white;
  margin: 0.4%;
}

.footerMenu {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0.4%;
}

.footerSeparator {
  display: inline-block;
  font-family: 'Noto Sans Thai';
  font-size: 1.25rem;
  font-weight: 100;
  line-height: 1.88875rem;
  color: #aaaaaa;
  margin: 0 1rem;
  justify-content: space-between;
}

.footerLink {
  display: inline-block;
  font-family: 'Noto Sans Thai';
  font-size: 1.25rem;
  font-weight: 900;
  line-height: 1.88875rem;
  color: white;
  margin: 0 1rem;
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none;
}

.overlayBackground {
  position: absolute;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  z-index: 50;
  cursor: pointer;
}

.overlayBackground.show {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.7);
}

.popUpContainer{
  height: 27%;
  width: 25%;
  background-color: #F9F4E7;
  border-radius: 1rem;
  position: relative;
}

.closeBtn{
  position: absolute;
  top: -2.5rem;
  right : -2.5rem;
  width: 5rem;
}

.headText{
  position: absolute;
  top : 12%;
  left: 10%;
  font-family: 'Noto Sans Thai';
  font-size: 2.4rem;
  font-weight: 500;
  color: #79694D;
}

.shareBtnContainer{
  position: absolute;
  top : 25%;
  left: 10%;
  width: 80%;
  height: 30%;
  display: flex;
  justify-content: space-around;
}
.shareBtn{
  width: 15%;
}
.shareBtnTitle{
  width: 15%;
  height: 100%;
  font-family: 'Noto Sans Thai';
  font-size: 1.55rem;
  font-weight: 500;
  line-height: 1.88875rem;
  color: 79694D;
  text-align: center;
}

.shareBtnTitleContainer{
  position: absolute;
  top : 55%;
  left: 10%;
  width: 80%;
  height: 10%;
  display: flex;
  justify-content: space-around;
}

.disabledInputContainer {
  position: absolute;
  top : 65%;
  left: 10%;
  width: 80%;
  display: flex;
  flex-direction: row;
  margin: 1%;
  margin-bottom: 3%;
  justify-content: space-between;
}

.disableInputStyle {
  background-color: #EFE7DE;
  border: 1px solid #DEC3B4;
  box-sizing: border-box;
  width: 90%;
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  color: #79694D;
  font-family: 'Noto Sans Thai', serif;
  font-weight: 500;
  font-size: 2rem;
  line-height: 3.5rem;

}