html {
  background-color: #101f36;

  @media screen and (max-width: 480px) {
    font-size: calc(1000vw/430);
  }
  @media screen and (min-width: 481px) and (max-width: 1024px) {
    font-size: calc(1000vw/1024);
  }
  @media screen and (min-width: 1025px) {
    font-size: calc(1000vw/1920);
  }
}

html, body {
  @media screen and (min-width: 1025px) {
    height: 100%;
    overflow-y: hidden;
    scrollbar-gutter: stable;
    -ms-overflow-style: none;
    scrollbar-width: none; 
  }
}

html::-webkit-scrollbar, body::-webkit-scrollbar {
  @media screen and (min-width: 1025px) {
    display: none;
  }
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Noto Sans Thai', sans-serif;

  @media screen and (min-width: 1025px) {
    scrollbar-width: none; /* Hide scrollbar for Firefox */
  }
}

* {
  margin: 0;
  padding: 0;
}