.section {
    position: relative;
    width: 100%;
    //height: 2700px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 8%;
}

.headtext {
    position: relative;
    width: 40%;
    height: auto;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.systemInfoContainer {
    height: 65%;
    width: 80%;
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 1%;
}

.systemInfoContent {
    position: relative;
    height: 100%;
    width: 22%;
    display: flex;
    align-items: center;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;

}

.systemInfoContent .defaultImage {
    width: 100%;
    height: auto;
}

.systemInfoContent:hover .defaultImage {
    opacity: 0;
}

.systemInfoContent:hover .hoverImage {
    opacity: 1;
}

.hoverImage {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: auto;
    opacity: 0;
}

.hoverButton{
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
    width: 30%;
    height : 6%;
    opacity: 0;
}

.systemInfoContent:hover .hoverButton {
    opacity: 1;
    z-index: 2;
}