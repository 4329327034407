.section {
    position: relative;
}

.bg {
    position: relative;
    width: 100vw;
    height: auto;
}


.btnPlay {
    position: absolute;
    top: 112.4vw;
    left: 8vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 67.4vw;
        left: 28vw;
    }
}
