.contentWrapper {
  background-color: #020509;
  line-height: 0;
}

.menuBarContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
}

.menuBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: auto;
}

.btnLogo {
  position: fixed;
  top: 0;
  left: 5vw;
  width: 25.81vw;
  height: auto;

  @media screen and (min-width: 481px) and (max-width: 1024px) {
    left: 4vw;
    width: 18.75vw;
  }
}

.popUpText {
  font-family: 'Noto Sans Thai';
  height: 6rem;
  font-size: 2rem;
  font-weight: 700;
  line-height: 6rem;
  color: #79694D;
  text-align: center;
  white-space: nowrap;
}

.position1 {
  position: absolute;
  top: 55%;
}

.position2 {
  position: absolute;
  top: 65%;
}

.btnHamburger {
  position: fixed;
  top: 4vw;
  right: 6vw;
  width: 6.28vw;
  height: auto;

  @media screen and (min-width: 481px) and (max-width: 1024px) {
    top: 3.9vw;
    right: 8vw;
    width: 4.39vw;
  }
}

.btnStore {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: auto;

  @media screen and (min-width: 481px) and (max-width: 1024px) {
    left: 15.87vw;
    width: 68.26vw;
  }
}