.background {
	position: absolute;
	background-image: url('https://sko-cdn.zoltangames.com/web/bg-desktop-2.png');
	background-repeat:no-repeat;
	background-position: center center;
	background-size: cover;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.shadow {
	position: absolute;
	background-image: url('https://sko-cdn.zoltangames.com/web/bg-desktop-2-shadow.png');
	background-repeat:no-repeat;
	background-position: center center;
	background-size: cover;
	width: 100%;
	height: 100%;
	z-index: 2;
}

.header {
	display: flex;
	position: fixed;
  align-items: center;
	background: #000000;
	width: 100%;
	height: 6.4583vw;
	z-index: 3;
}

.logo {
	display: inline-block;
	width: 5.104166666666667vw;
	height: 5.104166666666667vw;
	margin-left: 12.29166666666667vw;
	cursor: pointer;
}

.data_deletion {
	display: block;
	width: 62.5vw;
	height: 35.3125vw;
}

.container {
	display: flex;
	position: fixed;
  align-items: center;
	justify-content: center;
	top: 6.4583vw;
	width: 100vw;
	height: calc(100% - 6.4583vw);
	z-index: 3;
}

.title {
	display: inline-block;
	font-family: 'Noto Sans Thai';
	font-size: 3.2rem;
	font-weight: 700;
	line-height: auto;
	color: #F0E8D1;
	text-align: left;
	margin-left: 1.5625vw;
}

.subtitle {
	font-family: 'Noto Sans Thai';
	font-size: 2rem;
	font-weight: 700;
	line-height: auto;
	color: #FFFFFF;
	text-align: left;
}

.paragraph {
	font-family: 'Noto Sans Thai';
	font-size: 2rem;
	font-weight: 500;
	line-height: auto;
	color: #FFFFFF;
	text-align: left;
}
