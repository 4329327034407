.page1 {
  display: flex;
  flex-direction: column;
  width: 100vw;
  font-family: 'Noto Sans Thai';
  font-size: 1.4rem;
  font-weight: 900;
  color: #000000;
  text-align: left;
  overflow-x: hidden;

  @media screen and (min-width: 481px) {
    font-size: 2.4rem;
  }
}

.inputLabel {
  font-size: 3rem;
  font-weight: 700;
  line-height: 4.533rem;
  color: #5C5344;
  white-space: nowrap;

  @media screen and (min-width: 481px) {
    font-size: 5rem;
    margin-bottom: 2vw;
  }
}

.inputForm {
  width: 100%;
  height: 6.3rem;
  padding: 1rem;
  background-color: #EFE7DE;
  border: 1px solid #DEC3B4;
  box-sizing: border-box;
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 3.778rem;
  color: #B89C9C;

  @media screen and (min-width: 481px) {
    height: 13.55rem;
    padding-left: 4rem;
    border: 0.2rem solid #DEC3B4;
    font-size: 4rem;
    line-height: 4.778rem;
  }
}


::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #B89C9C;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #B89C9C;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #B89C9C;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #B89C9C;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #B89C9C;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #B89C9C;
}

.txtPreRegis {
  margin-top: 3vw;
  font-weight: 1.4rem;
  line-height: 2.115rem;
  color: #5C5344;

  @media screen and (min-width: 481px) {
    font-size: 2.4rem;
    line-height: 4rem;
  }
}

.txtPreRegis a {
  color: #F10000;
}

.btnNextContainer {
  display: flex;
  margin-top: 4vh;
  justify-content: center;
}

.page1 .btnNextContainer {
  margin-top: 23vw;

  @media screen and (min-width: 481px) {
    margin-top: 20vw;
  }
}

.page2 .btnNextContainer {
  @media screen and (min-aspect-ratio: 0.56) {
    margin-top: 2vh;
  }

  @media screen and (min-width: 481px) {
    margin-top: 3vh;

    @media screen and (min-aspect-ratio: 0.9) {
      margin-top: 2vh;
    }
  }
}

.page3 .btnNextContainer {
  @media screen and (min-aspect-ratio: 0.56) {
    margin-top: 2vh;
  }

  @media screen and (min-width: 481px) {
    margin-top: 8vh;

    @media screen and (min-aspect-ratio: 0.9) {
      margin-top: 1vh;
    }
  }
}

.page6 .btnNextContainer {
  margin-top: 14vh;

  @media screen and (min-width: 481px) {
    margin-top: 13vh;
  }
}

.btnNext {
  width: 42.56vw;

  @media screen and (min-aspect-ratio: 0.56) {
    width: 30vw;
  }

  @media screen and (min-width: 481px) {
    width: 29.1vw;

    @media screen and (min-aspect-ratio: 0.56) {
      width: 24vw;
    }
  }
}

.page2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Noto Sans Thai';
  font-size: 3rem;
  font-weight: 700;
  line-height: 4.533rem;
  margin-top: 5vh;
  color: #FFFFFF;

  @media screen and (min-aspect-ratio: 0.56) {
    margin-top: 0;
  }

  @media screen and (min-width: 481px) {
    margin-top: 0;
  }
}

.headerText {
  width: 100%;

  @media screen and (min-aspect-ratio: 0.56) {
    width: 85%;
  }

  @media screen and (min-width: 481px) {
    width: 100%;
  }
}

.characterVoteContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 1vw;

  @media screen and (min-width: 481px) {
    width: 60vw;
  }
}

.characterPlaceholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1vh;
  font-size: 2.2rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (min-aspect-ratio: 0.56) {
    margin-bottom: 0.5vh;
  }

  @media screen and (min-width: 481px) {
    margin-bottom: 1vh;

    @media screen and (min-aspect-ratio: 0.9) {
      margin-bottom: 0.5vh;
    }
  }
}

.checkmarkContainer {
  margin-bottom: 0;
}

.checkmark {
  height: 8vw;
  width: 8vw;
  margin: 3vw;
  background-color: #eee;
  border-radius: 50%;
  border: 0.1rem solid #D3A183;
  position: relative;
  display: inline-block;

  @media screen and (min-aspect-ratio: 0.56) {
    height: 6vw;
    width: 6vw;
    margin: 1.5vw;
  }

  @media screen and (min-width: 481px) {
    height: 5.18vw;
    width: 5.18vw;
    margin: 1vh;

    @media screen and (min-aspect-ratio: 0.9) {
      height: 4vw;
      width: 4vw;
      margin: 0.7vh;
    }
  }
}

.characterSRegister {
  width: 100%;
}

.characterSRegisOverlay {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.imageContainer {
  position: relative;
  width: 41.16vw;
  height: 57.21vw;

  @media screen and (min-aspect-ratio: 0.56) {
    width: 30.7vw;
    height: 42.56vw;
  }

  @media screen and (min-width: 481px) {
    width: 26.17vw;
    height: 36.43vw;

    @media screen and (min-aspect-ratio: 0.9) {
      width: 23vw;
      height: 31.93vw;
    }
  }
}

/* On mouse-over, add a grey background color */
.characterPlaceholder:hover input~.imageContainer .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.characterPlaceholder input:checked~.imageContainer .checkmark {
  background-color: #EEEBE6;
}

.characterPlaceholder:hover input~.imageContainer .characterSRegisOverlay {
  display: none;
}


.characterPlaceholder input:checked~.imageContainer .characterSRegisOverlay {
  display: none;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Hide the browser's default radio button */
.characterPlaceholder input {
  opacity: 0;
  cursor: pointer;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.characterPlaceholder input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.characterPlaceholder .checkmark:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4.19vw;
  height: 4.19vw;
  border-radius: 50%;
  background: #D3A183;

  @media screen and (min-aspect-ratio: 0.56) {
    width: 3vw;
    height: 3vw;
  }

  @media screen and (min-width: 481px) {
    width: 2.66vw;
    height: 2.66vw;

    @media screen and (min-aspect-ratio: 0.9) {
      width: 2vw;
      height: 2vw;
    }
  }
}

.page3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Noto Sans Thai';
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3.8rem;
  color: #79694D;

  @media screen and (min-aspect-ratio: 0.56) {
    margin-top: 6vw;
  }

  @media screen and (min-width: 481px) {
    margin-top: 0;
    font-size: 3.6rem;

    @media screen and (min-aspect-ratio: 0.56) {
      margin-top: 3vw;
    }
  }
}

.page3 .bigHeaderPreRegisText {
  width: 90.7vw;
  margin-bottom: 0;

  @media screen and (min-width: 481px) {
    margin-bottom: 3vw;
  }
}

.page3 .inputForm {
  @media screen and (min-width: 481px) {
    height: 11rem;
  }
}

.page3 .disableInputStyle2 {
  margin-bottom: 1.5vw;
}

.bigHeaderPreRegisText {
  margin-bottom: 3vw;
  text-align: center;
}

.smallHeaderPreRegisText {
  font-size: 2.8rem;

  @media screen and (min-width: 481px) {
    font-size: 5rem;
  }
}

.characterSelectedRegister {
  margin-top: 4.5vw;
  margin-bottom: 9vw;

  @media screen and (min-aspect-ratio: 0.56) {
    margin-top: 2vw;
    margin-bottom: 2vw;
    width: 40vw;
  }

  @media screen and (min-width: 481px) {
    margin-top: 6vw;
    margin-bottom: 3.5vw;
    width: 32.23vw;

    @media screen and (min-aspect-ratio: 0.9) {
      margin-top: 3vw;
      margin-bottom: 1vw;
      width: 29vw;
    }
  }
}

.disabledInputContainer {
  display: flex;
  width: 90%;
  margin-top: 3vw;
  margin-bottom: 3vw;

  @media screen and (min-width: 481px) {
    width: 80%;
  }
}

.disableInputStyle {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 5.289rem;
  color: #79694D;
  text-align: center;

  @media screen and (min-width: 481px) {
    font-size: 1.4rem;
    line-height: 2.115rem;
  }
}

.disableInputStyle2 {
  font-size: 1.4rem;
  font-weight: 900;
  line-height: 2.115rem;
  color: #79694D;

  @media screen and (min-width: 481px) {
    font-size: 2.4rem;
    line-height: normal;
  }
}

.page4 {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Noto Sans Thai';
  font-size: 2rem;
  font-weight: 700;
  line-height: 3.022rem;
  color: #79694D;

  @media screen and (min-width: 481px) {
    font-size: 4rem;
    line-height: normal;
  }
}

.page4 .bigHeaderPreRegisText {
  margin-bottom: 4vw;
}

.btnContainer {
  display: flex;
  flex-direction: column;
  height: 17%;
  justify-content: space-around;

  @media screen and (min-aspect-ratio: 0.56) {
    height: 22%;
  }

  @media screen and (min-width: 481px) {
    height: 22%;
  }
}

.btnCreateTeam {
  width: 42.36vw;

  @media screen and (min-width: 481px) {
    width: 29.1vw;
  }
}

.btnJoinTeam {
  width: 42.36vw;

  @media screen and (min-width: 481px) {
    width: 29.1vw;
  }
}

.page5 {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Noto Sans Thai';
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 5.289rem;
  color: #79694D;

  @media screen and (min-width: 481px) {
    font-size: 5rem;
    line-height: 5.289rem;
  }
}

.page5 .bigHeaderPreRegisText {
  margin-bottom: 5vw;
}

.page5 .inputForm {
  @media screen and (min-width: 481px) {
    height: 11rem;
  }
}

.page5 .disableInputStyle {
  @media screen and (min-width: 481px) {
    font-size: 5rem;
  }
}

.page5 .disableInputStyle2 {
  @media screen and (min-width: 481px) {
    font-size: 2.6rem;
  }
}

.page6 {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Noto Sans Thai';
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 5.289rem;
  color: #79694D;

  @media screen and (min-width: 481px) {
    font-size: 5rem;
  }
}

.page6 .bigHeaderPreRegisText {
  margin-bottom: 5vw;
}

.page6 .inputForm {
  text-align: left;

  @media screen and (min-width: 481px) {
    font-size: 5rem;
    line-height: 5.789rem;

    height: 11rem;
  }
}

.btnCopy {
  width: 7.3vw;
  height: 8.14vw;
  margin-top: 2vw;
  margin-left: 3vw;

  @media screen and (min-width: 481px) {
    width: 5.93vw;
    height: 6.35vw;
  }
}

.btnPaste {
  width: 6.51vw;
  height: 8.14vw;
  margin-top: 2vw;
  margin-left: 3vw;

  @media screen and (min-width: 481px) {
    width: 5.93vw;
    height: 6.35vw;
  }
}

.page7 {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Noto Sans Thai';
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 4.835rem;
  color: #79694D;

  @media screen and (min-aspect-ratio: 0.56) {
    font-size: 3rem;
  }

  @media screen and (min-width: 481px) {
    font-size: 5rem;
  }
}

.page7 .bigHeaderPreRegisText {
  margin-bottom: 5vh;

  @media screen and (min-aspect-ratio: 0.56) {
    margin-bottom: 1vh;
  }

  @media screen and (min-width: 481px) {
    margin-bottom: 5vh;

    @media screen and (min-aspect-ratio: 0.9) {
      margin-bottom: 3vh;
    }
  }
}

.teamRewardContainer {
  display: flex;
  flex-wrap: wrap;
  width: 95%;
  margin: 1vh 0;

  @media screen and (min-width: 481px) {
    margin: 2vh 0;
    font-size: 5rem;
  }
}

.btnRewardContainer {
  display: flex;
  width: 86%;
  justify-content: space-around;
  margin-top: 3vh;

  @media screen and (min-aspect-ratio: 0.56) {
    margin-top: 2vh;
  }

  @media screen and (min-width: 481px) {
    margin-top: 4vh;

    @media screen and (min-aspect-ratio: 0.9) {
      margin-top: 2vh;
    }
  }
}

.page8 .btnRewardContainer {
  @media screen and (min-width: 481px) {
    margin-top: 6vh;

    @media screen and (min-aspect-ratio: 0.9) {
      margin-top: 6vh;
    }
  }
}

.btnShare {
  width: 36.28vw;
  height: 9.75vw;

  @media screen and (min-aspect-ratio: 0.56) {
    width: 30vw;
    height: 8.07vw;
  }

  @media screen and (min-width: 481px) {
    width: 30vw;
    height: 8.07vw;
  }
}

.page8 {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Noto Sans Thai';
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 4.835rem;
  color: #79694D;

  @media screen and (min-aspect-ratio: 0.56) {
    font-size: 3rem;
  }

  @media screen and (min-width: 481px) {
    font-size: 5rem;
  }
}

.page8 .bigHeaderPreRegisText {
  margin-bottom: 5vh;

  @media screen and (min-aspect-ratio: 0.56) {
    margin-bottom: 2vh;
  }

  @media screen and (min-width: 481px) {
    margin-bottom: 5vh;

    @media screen and (min-aspect-ratio: 0.9) {
      margin-bottom: 3vh;
    }
  }
}

.shareContainer {
  width: 80vw;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3vw;

  @media screen and (min-width: 481px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.itemWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btnShareIcon {
  width: 20vw;
  height: 20vw;

  @media screen and (min-width: 481px) {
    width: 15vw;
    height: 15vw;
  }
}

.itemLabel {
  margin-top: 1vw;
  font-size: 2rem;

  @media screen and (min-width: 481px) {
    font-size: 3rem;
  }
}

.page9 {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Noto Sans Thai';
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 4.835rem;
  color: #79694D;

  @media screen and (min-aspect-ratio: 0.56) {
    font-size: 3rem;
  }

  @media screen and (min-width: 481px) {
    font-size: 5rem;
  }
}

.page9 .btnNextContainer {
  margin-top: 23vw;

  @media screen and (min-width: 481px) {
    margin-top: 5vw;
  }
}

.infoText {
  width: 80vw;
  height: 58vw;

  @media screen and (min-aspect-ratio: 0.56) {
    width: 50vw;
    height: 36vw;
  }

  @media screen and (min-width: 481px) {
    width: 50vw;
    height: 36vw;
  }
}

.character {
  width: 60vw;
  height: 61.5vw;

  @media screen and (min-aspect-ratio: 0.56) {
    width: 40vw;
    height: 41vw;
  }

  @media screen and (min-width: 481px) {
    width: 40vw;
    height: 41vw;
  }

}