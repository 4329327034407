.container {
    height: 100vh;
    min-width: 1025px;
    min-height: 560px;
    overflow-y:  scroll;
    scroll-behavior: smooth;
    scrollbar-gutter: stable;
    -ms-overflow-style: none;
    scrollbar-width: none; 

}

.container ::-webkit-scrollbar {
    display: none;
}


.pageContainer {
    height: 100vh;
    min-width: 1025px;
    min-height: 560px;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.headerMenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 10%;
    min-width: 1025px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 11;
}

.menuBar {
    display: flex;
    padding-top: 1%;
    height: 90%;
    width: 56%;
    justify-content: space-evenly;
}

.menuBar a {
    text-align: center;
    display: flex;
    text-align: center;
    font-family: 'Noto Sans Thai', sans-serif;
    font-weight: 300;
    font-size: 1.8rem;
    color: #ffffff;
}

.activeTab {
    color: #FEFFC3 !important;
    text-decoration: underline;
}

.pageTopContainer {
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 1;
    z-index: 10;
    pointer-events: none;
}

.headerText {
    font-family: 'Noto Sans Thai', sans-serif;
    font-weight: 700;
    font-size: 3.0rem;
    color: #ffffff;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.5);
    z-index: 3;
}

.txtCount {
    font-family: 'Noto Sans Thai';
	font-size: 6.4rem;
	font-weight: 600;
	line-height: 6rem;
    position: absolute;
    top: 56%;
    left: 50%;
    transform: translateX(-50%);
	color: #FFFFFF;
	text-align: center;
}
.popUpText{
    font-family: 'Noto Sans Thai';
    height: 6rem;
	font-size: 5.5rem;
	font-weight: 700;
	line-height: 6rem;
	color: #79694D;
	text-align: center;
    white-space: nowrap;
}

.position1{
    position: absolute;
    top: 55%;
    left: 50%;
}
.position2{
    position: absolute;
    top: 65%;
    left: 53%;
}
.btnPosition {
    position: absolute;
    width: 25vw;
    bottom: 9.5%;
    left: 46%;
    display: flex;
    justify-content: space-around;
}
.txtHeader2 {
    font-family: 'Noto Sans Thai', sans-serif;
    font-weight: 700;
    font-size: 2.4rem;
    color: #ffffff;
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translateX(-50%);
    text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.5);
    z-index: 3;
}
.menuContentContainer{
    position: absolute;
    width: 50%;
    height: 35%;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    font-family: 'Noto Sans Thai', sans-serif;
    font-weight: 700;
    font-size: 2.0rem;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.badgeContainer{
    display: flex;
    width: 80%;
    height: 50%;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 1%;
}

.page2{
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.contentContainer{
    width: 100%;
    height: 100vh;
    overflow: hidden;
}
.playerContainer{
    display: flex;
    width: 80%;
    height: 20%;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 1%;
}
.btnPlayer{
    height: 100%;
}

.row {
    height: 100px;
    background-color: #ff6347; /* Tomato color */
  }

.row2{
    height: 100px;
    background-color: #007b2f; /* Tomato color */
}