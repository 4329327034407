.cdn-video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    // pointer-events: none; 
  
    .cdn-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  
    .video-poster {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      z-index: 1; /* Ensure poster is above video */
    }
  
    &.video-playing {
      .video-poster {
        display: none; /* Hide poster when video is playing */
      }
    }
  }