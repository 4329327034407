.section {
    position: relative;
}
.bg {
    position: relative;
    width: 100vw;
    height: auto;
}


.imgInvite {
    position: absolute;
    width: 33.72vw;
    height: auto;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        width: 20.3vw;
    }
}

.inviteFriend1 {
    top: 69.3vw;
    left: 8.3vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 39vw;
        left: 21.3vw;
    }
}

.inviteFriend2 {
    top: 69.3vw;
    left: 56vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 39vw;
        left: 57.6vw;
    }
}

.inviteFriend3 {
    top: 125vw;
    left: 8.3vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 72.6vw;
        left: 21.3vw;
    }
}

.inviteFriend4 {
    top: 125vw;
    left: 56vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 72.6vw;
        left: 57.6vw;
    }
}

.none {
    display: none;
}

.checked {
    display: unset;
    opacity: 0.9;
}

.btnRegister {
    position: absolute;
    top: 50.5vw;
    left: 33.26vw;
    width: 33.49vw;
    height: auto;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 28vw;
        left: 39.84vw;
        width: 20.3vw;
    }
}

.btnInfo {
    position: absolute;
    top: 51.5vw;
    right: 24vw;
    width: 7vw;
    height: 7vw;
    height: auto;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 28.9vw;
        right: 33vw;
        width: 3.6vw;
        height: 3.6vw;
    }
}

