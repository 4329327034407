* {
	box-sizing: border-box;
}

.container {
	display: grid;
	position: relative;
	overflow: hidden;
	width: 67.70833333333333vw;
	grid-template: auto / 1fr 5rem;
}

.containerMobile {
	display: grid;
	position: relative;
	overflow: hidden;
	grid-template: auto / 1fr 1rem;

	@media screen and (orientation: landscape) {
    @media screen and (max-height: 480px) {
			width: 100vw;
			height: 80dvh;
		}
		@media screen and (min-height: 481px) {
			width: 100vw;
			height: 80dvh;
		}
  }
  @media screen and (orientation: portrait) {
    @media screen and (max-width: 480px) {
			width: 100vw;
			height: 90dvh;
		}
		@media screen and (min-width: 481px) {
			width: 100vw;
			height: 80dvh;
		}
  }
}

.content {
	-ms-overflow-style: none;
	overflow: auto;
	scrollbar-width: none;
	padding: 0 1.6rem;
  height: 36.45833333333333vw;
}

.contentMobile {
	-ms-overflow-style: none;
	overflow: auto;
	scrollbar-width: none;

	@media screen and (orientation: landscape) {
    @media screen and (max-height: 480px) {
			padding: 0 4vw 0 5.7vw;
			height: 100%;
		}
		@media screen and (min-height: 481px) {
			padding: 0 4vw 0 5.7vw;
			height: 100%;
		}
  }
  @media screen and (orientation: portrait) {
    @media screen and (max-width: 480px) {
			padding: 0 4vw 0 5.7vw;
    	height: 100%;
		}
		@media screen and (min-width: 481px) {
			padding: 0 4vw 0 5.7vw;
			height: 100%;
		}
  }
}

.content::-webkit-scrollbar {
	display: none;
}

.scrollbar {
	display: grid;
	gap: 1.6rem;
	grid-auto-flow: row;
	grid-template: auto 1fr auto / 1fr;
	padding: 1.6rem;
	place-items: center;
}

.scrollbarMobile {
	display: grid;
	grid-template: auto 1fr auto / 1fr;
}

.track_and_thumb {
	display: block;
	height: 100%;
	position: relative;
	width: 1rem;
}

.track {
	position: absolute;
	width: 1rem;
	top: 0;
	bottom: 0;
	cursor: pointer;
}

.thumb {
	position: absolute;
	background-color: #55555566;
	border-radius: 1rem;
	width: 1rem;
	mix-blend-mode: overlay;
}

.button {
	background-color: var(--ui-2);
	border: none;
	border-radius: 50%;
	color: #cecdc3;
	cursor: pointer;
}

.button_up {
	background-color: #af3029;
	opacity: 0;
}
.button_up:hover {
	background-color: #d14d41;
}

.button_down {
	background-color: #a02f6f;
	opacity: 0;
}
.button_down:hover {
	background-color: #ce5d97;
}
