html {
	background-color: #030408 !important;

  @media screen and (orientation: landscape) {
    @media screen and (max-height: 480px) {
			font-size: calc(500vh/430) !important;
		}
		@media screen and (min-height: 481px) {
			font-size: calc(1000vh/1024) !important;
		}
  }
  @media screen and (orientation: portrait) {
    @media screen and (max-width: 480px) {
			font-size: calc(1000vw/430) !important;
		}
		@media screen and (min-width: 481px) {
			font-size: calc(1000vw/1024) !important;
		}
  }
}

.background {
	position: absolute;
	background-image: url('https://sko-cdn.zoltangames.com/web/bg-desktop-2.png');
	background-repeat:no-repeat;
	background-position: center center;
	background-size: cover;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.shadow {
	position: absolute;
	background-image: url('https://sko-cdn.zoltangames.com/web/bg-desktop-2-shadow.png');
	background-repeat:no-repeat;
	background-position: center center;
	background-size: cover;
	width: 100%;
	height: 100%;
	z-index: 2;
}

.header {
	display: flex;
	position: fixed;
  align-items: center;
	background: #000000;
	width: 100%;
	z-index: 3;

	@media screen and (orientation: landscape) {
		height: 11.48144vh;
  }
  @media screen and (orientation: portrait) {
		height: 11.48144vw;
  }
}

.logo {
	display: inline-block;
	cursor: pointer;

	@media screen and (orientation: landscape) {
		width: 9.074074074074074vh;
		height: 9.074074074074074vh;
		margin-left: 5vw;
  }
  @media screen and (orientation: portrait) {
		width: 9.074074074074074vw;
		height: 9.074074074074074vw;
		margin-left: 5vw;
  }
}

.data_deletion {
	display: block;

	@media screen and (max-width: 480px) {
    width: 87vw;
  }
  @media screen and (min-width: 481px) {
    width: 89vw;
  }
}

.container {
	display: flex;
	position: fixed;
  align-items: center;
	justify-content: center;
	width: 100vw;
	z-index: 3;

	@media screen and (orientation: landscape) {
		top: 11.48144vh;
		height: calc(100% - 11.48144vh);
  }
  @media screen and (orientation: portrait) {
		top: 11.48144vw;
		height: calc(100% - 11.48144vw);
  }
}

.title {
	display: inline-block;
	font-family: 'Noto Sans Thai';
	font-weight: 700;
	line-height: auto;
	color: #F0E8D1;
	text-align: left;
	margin-left: 1.5625vw;

	@media screen and (max-width: 480px) {
    font-size: 1.433rem;
  }
  @media screen and (min-width: 481px) {
		font-size: 3.5rem;
  }
}

.subtitle {
	font-family: 'Noto Sans Thai';
	font-weight: 700;
	line-height: auto;
	color: #FFFFFF;
	text-align: left;

	@media screen and (max-width: 480px) {
    font-size: 1.593rem;
  }
  @media screen and (min-width: 481px) {
		font-size: 3.792rem;
  }
}

.paragraph {
	font-family: 'Noto Sans Thai';
	font-weight: 500;
	line-height: auto;
	color: #FFFFFF;
	text-align: left;

	@media screen and (max-width: 480px) {
    font-size: 1.593rem;
  }
  @media screen and (min-width: 481px) {
		font-size: 3.792rem;
  }
}
