.section {
    position: relative;
}

.bg {
    position: relative;
    width: 100vw;
    height: auto;
}

.btnPlay {
    position: absolute;
    top: 33vh;
    left: 30.93vw;

    @media (min-aspect-ratio: 0.5738162) {
        top: 25vh;
    }

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 32.6vh;
        left: 38.18vw;
    }
}

.textContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 54vh;
    width: 100vw;

    @media (min-aspect-ratio: 0.5738162) {
        top: 47vh;
    }

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 50.7vh;
    }
}

.txtRegister {
    width: 89.07vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        width: 55vw;
        margin-bottom: 1.7vh;
    }
}

.txtCount {
    font-family: 'Noto Sans Thai';
	font-size: 6rem;
	font-weight: 900;
	line-height: 6rem;
	color: #FFFFFF;
	text-align: center;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        font-size: 11rem;
        line-height: 11rem;
    }
}

.btnRegister {
    position: absolute;
    top: 71vh;
    left: 15vw;
    width: 70vw;
    height: auto;

    @media (min-aspect-ratio: 0.5738162) {
        top: 65vh;
    }

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 69vh;
        left: 28.37vw;
        width: 43.26vw;
    }
}
