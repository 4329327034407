.rewardPlaceholder {
  width: 50%;

  @media screen and (min-width: 481px) {
    margin-bottom: 1vw;
  }
}

.rewardContainer {
  display: flex;
  justify-content: center;
}

.rewardContainerImg {
  position: relative;
}

.imgReward {
  width: 33.95vw;
  height: 33.95vw;

  @media screen and (min-aspect-ratio: 0.56)  {
    width: 24.71vw;
    height: 24.71vw;
  }

  @media screen and (min-width: 481px) {
    width: 24.71vw;
    height: 24.71vw;

    @media screen and (min-aspect-ratio: 0.9)  {
      width: 22.71vw;
      height: 22.71vw;
    }
  }
}

.imgReward.base {
  opacity: 1;
}

.imgReward.checked {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.text {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3.778rem;
  color: #685A43;
  text-align: center;

  @media screen and (min-aspect-ratio: 0.56)  {
    font-size: 2.3rem;
    line-height: 3rem;
  }

  @media screen and (min-width: 481px) {
    font-size: 3.6rem;
    line-height: 5.5rem;
  }
}
