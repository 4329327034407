.section {
    position: relative;
    width: 100%;
    //height: 2700px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 8%;
}

.headtext {
    position: relative;
    width: 40%;
    height: auto;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

//-- Button
.btnContainer{
    width: 30%;
    height: 5%;
    margin-top: 1%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.contentFrame{
    width: 56%;
    height: 78%;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}
.contentHeader{
    width: 43%;
    height: 10%;
    position: absolute;
    top: 22%;
    left: 50%;
    transform: translateX(-50%);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Noto Sans Thai';
    font-weight: 700;
    color: white;
    font-size: 2.7rem;
}

.count{
    padding-right: 5%;
    padding-left: 5%;
}

// -- Reward
.rewardContainer{
    width: 72%;
    height: 45%;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(91,27,27,0.5);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    font-family: 'Noto Sans Thai';
    font-weight: 600;
    color: white;
    white-space: nowrap;
    font-size: 2.5rem;
}

.reward{
    width: 21%;
    height: 90%;
}

.rewardIcon{
    position: relative;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 70%;
    margin-bottom: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon{
    width: 70%;
}
.accepted{
    position: absolute;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}