.videoContainer {
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }
  
  .thumbnailContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  
  .thumbnail {
    object-fit: contain;
    width: 100%; 
    height: 100%; 
    display: block; 
    margin: auto;
  }
  
  .videoContainer.playing {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw !important;
    height: 100vh !important;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.7); 
  }


  .player {
    display: none;
    position: relative;
    width: 100%;
    height: 80%;
    z-index: 4;

    @media screen and (max-width: 1024px) {
      height: 60%;
    }
  }

  .player.playing {
    display: block;
  }

  .btnClose {
    position: absolute;
    width: 1.5%;
    height: auto;
    top: -3%;
    right: 0.5%;
    z-index: 5;

    @media screen and (max-width: 480px) {
      width: 6%;
      top: -5%;
      right: 0.5%;
    }

    @media screen and (min-width: 481px) and (max-width: 1024px) {
      width: 5%;
      top: -6%;
      right: 0.5%;
    }
  }