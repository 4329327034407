.overlayBackground {
  position: fixed;
  overflow: hidden;
  transition: transform 0.1s, background-color 0.1s;
  width: 100vw;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 101;
  cursor: pointer;
}

.overlayBackground.show {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  visibility: visible;
  background-color: rgba(125,158,205, 1); 
}

.popOutContainer  {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;   
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  z-index: 100;
  background-color: black;
}

.btnClose {
  position: absolute;
  width: 7.44%;
  height: auto;
  top: 3%;
  right: 6.3%;
}

.hamburgerMenu  {
  display: flex;
  width: 100%;
  height: 6.2rem;
  align-items: flex-start;
  padding-left: 10%;
  font-family: 'Noto Sans Thai';
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 3.778rem;
  color: white;

  @media screen and (min-width: 481px) and (max-width: 1024px) {
    font-size: 5rem;
    line-height: auto;
    margin-bottom: 2.5vh;
  }
}

.active{
  font-size: 3rem;
  line-height: 4.533rem;
  color: #65B5FF;
  text-decoration: underline;

  @media screen and (min-width: 481px) and (max-width: 1024px) {
    font-size: 6rem;
    line-height: auto;
  }
}

.menuContent  {
  width: 100%;
  height: 82%;
}

.popUpContent {
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
