.section {
    position: relative;
}

.bg {
    position: relative;
    width: 100vw;
    height: auto;
}

.btnPlay {
    position: absolute;
    left: 35vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        left: 40vw;
    }
}

.system1 {
    top: 50vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 32vw;
    }
}

.system2 {
    top: 156vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 100.5vw;
    }
}

.system3 {
    top: 276vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 176vw;
    }
}

.system4 {
    top: 376vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 242.5vw;
    }
}