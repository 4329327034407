.followReward {
    height: 86%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    .gold{
      width:63%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

    }
  }
  .followRewardText{
    text-align: center;
    display: flex;
    font-size: 1.2rem;
    text-align: center;
    font-family: 'Noto Sans thai';
    font-weight: 700;
    color: #fff;
    align-items: center;
    flex-direction: column;
    width: 100%;
    white-space: pre-line;
  }
  .quantity{
    font-size: 1.8rem;
    font-weight: 900;
  }
 .followRewardContent{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 }
  .hover-info {
    position: absolute;
    width: 23rem;
    height: 9rem;
    z-index: 100;
    color: white;
    font-family: 'Noto Sans thai';
    font-size: 1.8rem;
    top : 0%;
    left: 75%;
    opacity: 0;
    background-color: #000000;
    display: flex;
    justify-content: center;
    padding: 2rem;
    align-items: center;
    white-space: pre-line;
    transition: transform 0.3s ease-in-out;
  }

  .hovered {
    opacity: 0.85;
  }