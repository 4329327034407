.popOutContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.overlayBackground {
  position: absolute;
  overflow: hidden;
  transition: transform 0.1s, background-color 0.1s;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  z-index: 50;
  cursor: pointer;
}

.overlayBackground.show {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.7);
}

.imgPopup {
  position: relative;

  @media screen and (max-width: 480px) {
    width: 90vw;
    height: 108vw;

    @media (min-aspect-ratio: 0.5738162) {
      width: 70vw;
      height: 84vw;
    }
  }

  @media screen and (min-width: 481px) and (max-width: 1024px) {
    height: 90vw;
    width: 74vw;

    @media (min-aspect-ratio: 0.5738162) {
      height: 70vw;
      width: 61vw;
    }
  }

  @media screen and (min-width: 1025px) {
    height: 90vh;
    width: 74vh;
  }
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.clickableArea {
  position: absolute;
  cursor: pointer;
}

.position1 {
  @media screen and (max-width: 480px) {
    height: 6.5vw;
    width: 6.5vw;
    top: 18%;
    right: 10.8%;
  }

  @media screen and (min-width: 481px) and (max-width: 1024px) {
    height: 6vw;
    width: 6vw;
    top: 18%;
    right: 10.8%;
  }

  @media screen and (min-width: 1025px) {
    height: 5.6vh;
    width: 5.6vh;
    top: 18%;
    right: 10.8%;
  }

}

.position2 {
  @media screen and (max-width: 480px) {
    height: 7.2vw;
    width: 27.6vw;
    bottom: 1.5%;
    left: 52%;
    transform: translateX(-50%);
  }

  @media screen and (min-width: 481px) and (max-width: 1024px) {
    height: 5.6vw;
    width: 20.6vw;
    bottom: 1.8%;
    left: 52%;
    transform: translateX(-50%);
  }

  @media screen and (min-width: 1025px) {
    height: 5.6vh;
    width: 20.6vh;
    bottom: 1.5%;
    left: 52%;
    transform: translateX(-50%);
  }
}