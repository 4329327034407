.popOutContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 101;
    background-color: #EEEBE6;
}

.overlayBackground {
    position: fixed;
    overflow: hidden;
    transition: transform 0.1s, background-color 0.1s;
    width: 100vw;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    z-index: 101;
    cursor: pointer;
}

.overlayBackground.show {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.7); 
  }

.popUpContent {
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.btnClose {
  position: absolute;
  width: 7.91%;
  height: auto;
  top: 3%;
  right: 4.3%;

  @media screen and (min-aspect-ratio: 0.56)  {
    width: 6%;
    top: 2%;
    right: 3%;
  }

  @media screen and (min-width: 481px) {
    top: 2%;
    right: 2.3%;
    width: 5.47%;
  }
}