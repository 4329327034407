.warningPage{
    width: 100vw;
    display: flex;
    height: 100vh;
    background-color: grey;
    justify-content: center;
    align-items: center;
}

.warningContent {
    display: flex;
    width: 70vh;
    height: 70vh;
    align-items: center;
    flex-direction: column;
}

.warningImg {
    width: 90%;
}

.warningTxt {
    width: 100vw;
    margin-bottom: 5vw;
    font-family: 'Noto Sans Thai';
	font-size: 3rem;
	font-weight: 700;
	line-height: 3.022rem;
	color: #000000;
	text-align: center;

    @media screen and (min-width: 1025px) {
        font-size: 6rem;
    }
}
