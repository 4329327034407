.section {
    position: relative;
}

.bg {
    position: relative;
    width: 100vw;
    height: auto;
}


.imgReward {
    position: absolute;
    width: 37.44vw;
    height: 36.98vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        width: 22.36vw;
        height: 22.07vw;
    }
}

.reward1 {
    top: 33.7vw;
    left: 6vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 19.7vw;
        left: 21vw;
    }
}

.reward2 {
    top: 33.7vw;
    left: 55.5vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 19.7vw;
        left: 57.4vw;
    }
}

.reward3 {
    top: 81.7vw;
    left: 6vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 48.5vw;
        left: 21vw;
    }
}

.reward4 {
    top: 81.7vw;
    left: 55.5vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 48.5vw;
        left: 57.4vw;
    }
}

.reward5 {
    top: 132.5vw;
    left: 31.28vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 78.7vw;
        left: 39vw;
    }
}

.imgRewardFb {
    position: absolute;
    width: 22.79vw;
    height: 22.79vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        width: 16.6vw;
        height: 16.6vw;
    }
}

.rewardFb1 {
    top: 46.9vw;
    left: 10vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 38.9vw;
        left: 6vw;
    }
}

.rewardFb2 {
    top: 46.9vw;
    left: 38.5vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 38.9vw;
        left: 24.2vw;
    }
}

.rewardFb3 {
    top: 46.9vw;
    left: 66.5vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 38.9vw;
        left: 42vw;
    }
}

.rewardFb4 {
    top: 76.3vw;
    left: 24vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 38.9vw;
        left: 59.5vw;
    }
}

.rewardFb5 {
    top: 76.3vw;
    left: 53vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 38.9vw;
        left: 77.5vw;
    }
}

.rewardFb6 {
    top: 123.2vw;
    left: 10vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 80.7vw;
        left: 6vw;
    }
}

.rewardFb7 {
    top: 123.2vw;
    left: 38.5vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 80.7vw;
        left: 24.2vw;
    }
}

.rewardFb8 {
    top: 123.2vw;
    left: 66.5vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 80.7vw;
        left: 42vw;
    }
}

.rewardFb9 {
    top: 152.3vw;
    left: 24vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 80.7vw;
        left: 59.5vw;
    }
}

.rewardFb10 {
    top: 152.3vw;
    left: 53vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 80.7vw;
        left: 77.5vw;
    }
}

.none {
    display: none;
}

.checked {
    opacity: 0.9;
}

.imgReward.checked {
    display: unset;
    width: 32.44vw;
    height: 32.44vw;
    margin-left: 2.5vw;
    margin-top: 2vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        width: 19vw;
        height: 19vw;
        margin-left: 1.5vw;
        margin-top: 1.2vw;
    }
}

.imgRewardFb.checked {
    display: unset;
    width: 21.4vw;
    height: 21.4vw;
    margin-left: .812vw;
    margin-top: .47vw;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        width: 14.6vw;
        height: 14.6vw;
        margin-left: 1vw;
        margin-top: 1vw;
    }
}

.btnFollowFb {
    position: absolute;
    top: 32.8vw;
    left: 21.4vw;
    width: 57.21vw;
    height: auto;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 26.7vw;
        left: 33.4vw;
        width: 33.2vw;
    }
}

.btnJoinGroup {
    position: absolute;
    top: 109.5vw;
    left: 21.4vw;
    width: 57.21vw;
    height: auto;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 69.7vw;
        left: 33.4vw;
        width: 33.2vw;
    }
}

.btnInfo {
    position: absolute;
    top: 26vw;
    right: 2vw;
    width: 6vw;
    height: 6vw;
    height: auto;

    @media screen and (min-width: 481px) and (max-width: 1024px) {
        top: 13.9vw;
        right: 18.3vw;
        width: 3.6vw;
        height: 3.6vw;
    }
}
