.overlay-menu{
    width: 10%;
    min-width: 120px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    right: 4%;
    bottom: 4%;
    z-index: 4;
    transition: opacity 0.1s ease-in-out;
    opacity: 0;
    visibility: hidden;

}

.marker1{
  position: absolute;
  top: 20%;
  right: 3%;
  width: 56%;
  height: 20%;
  opacity: 0;
}
.marker2{
  position: absolute;
  top: 46%;
  right: 3%;
  width: 56%;
  height: 20%;
  opacity: 0;
}
.overlay-menu.visible {
    opacity: 1;
    visibility: visible;
  }

  .pageMarkContainer{
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: relative;
  }

  .labelContainer{
    width: 90%;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
  }


  .markerContainer{
    width: 10%;
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
  }

  .progressLine{
    position: absolute;
    border-left: 1px solid #FFFFFF; 
    height: 80%;
    z-index: -1;
  }
  .imgContainer{
    width: 100%;
    aspect-ratio : 1 / 1;
    position: relative;
  }
  .pageMarker{
    width: 100%;
    display: flex;
    flex: 0 0 10%;
    position: relative;
    flex-direction: row;
    justify-content: flex-end;
  }

  .pageMarker label{
    margin-right: 10%;
    font-family: 'DB Helvethaica X Cond';
    font-size: 1.5rem;
    color: #FFFFFF;
  }

  .communityButtonContainer{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content:flex-end;
  }
  