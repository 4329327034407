.container {
    margin-left: 23%;
    height: 80%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.container2 {
    margin-left: 23%;
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.inputForm {
    background-color: #EFE7DE;
    border: 1px solid #DEC3B4;
    box-sizing: border-box;
    width: 50rem;
    height: 5rem;
    font-size: 3rem;
    padding-left: 1rem;
    font-family: 'Noto Sans Thai', serif;
    font-weight: 900;
}

.disableInputStyle {
    width: 30vw !important;
    display: flex;
    justify-content: center;
    text-align: center;
    color: #79694D;
    font-family: 'Noto Sans Thai', serif;
    font-weight: 900;
    font-size: 2rem;
    line-height: 3.5rem;

}

.EmailInputContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 15%;
}

.disabledInputContainer {
    display: flex;
    flex-direction: row;
    margin: 1%;
    justify-content: space-between;
}


.EmailInputContainer .InputLabel {
    font-family: 'Noto Sans Thai', serif;
    font-weight: 700;
    font-size: 5rem;
    width: 18%;
    line-height: 3.5rem;
    color: #79694D;
    margin-right: 10px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    text-align: center;
}


.disabledInputContainer .InputLabel {
    font-family: 'Noto Sans Thai', serif;
    font-weight: 700;
    text-align: left;
    margin-right: 5%;
    color: #79694D;
    white-space: nowrap;
    display: flex;
    align-items: center;

}
.infoContainer{
    position: relative;
    height: 87%;
    width: 100%;
    left: 2%;
    top: 1.7%;
}

.infoText{
    position: absolute;
    height: 35vh;
    width: 50vh;
    left: 17%;
    top: 22%;
}
.character{
    position: absolute;
    height: 57vh;
    width: 53vh;
    right: -3%;
    bottom: 0;

}
.preRegisText {
    margin-top: 2vh;
    margin-left: 33%;
    font-family: 'Noto Sans Thai', serif;
    font-weight: 900;
    color: #79694D;
    width: 40vw;
    font-size: 2rem;
    line-height: 2.5rem;
}

.preRegisText a {
    color: #F10000;
}

.btnPosition {
    position: absolute;
    width: 25vw;
    left: 48%;
    display: flex;
    justify-content: space-around;
    transform: translateX(-50%);
}

.headTextPosition {
    width: 50%;
    position: absolute;
    top: -15%
}

.position1 {
    bottom: -5%;
}

.position2 {
    bottom: 1%;
}

.formStyle {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 2%;
}

.characterContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 23%;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.charBox {
    width: 80%;
    display: flex;
    justify-content: space-between;
}

.characterSelectedContainer {
    width: 18%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.shareBtn {
    width: 3vw;
}

.bigHeaderPreRegisText {
    color: #79694D;
    font-family: 'Noto Sans Thai', serif;
    font-weight: 700;
    font-size: 4rem;
}

.bigHeaderPositionPage6 {
    margin-bottom: 2%;
}

.smallHeaderPreRegisText {
    font-family: 'Noto Sans Thai', serif;
    font-weight: 700;
    color: #79694D;
    font-size: 3rem;
}

.shareButtonContainer {
    height: 8%;
    width: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.teamSelectionContainer {
    padding-left: 8%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    height: 60%;
}

.teamIcon {
    height: 75%;
}

.regisRewardContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 35%;
    justify-content: center;
    align-items: flex-start;
}

.contentHeader{
    width: 50%;
    height: 8%;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Noto Sans Thai';
    font-weight: 700;
    color: white;
    font-size: 2.7rem;
}

.count{
    padding-right: 5%;
    padding-left: 5%;
}

.rewardContainer{
    width: 72%;
    height: 30%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    font-family: 'Noto Sans Thai';
    font-weight: 600;
    color: #79694D;
    white-space: nowrap;
    font-size: 2.5rem;
    margin-top: 3%;
    margin-bottom: 3%;
    
}
.reward{
    height: 100%;
}
.rewardText{
    height: 30%;
}
.rewardIcon{
    position: relative;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    width: auto;
    height: 70%;
    margin-bottom: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.icon{
    position: absolute;
    height: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.accepted{
    position: absolute;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
.rewardBg {
    height: 100%;
}