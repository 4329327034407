.popOutContainer{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 100;
}

.overlayBackground {
    position: absolute;
    overflow: hidden;
    transition: transform 0.1s, background-color 0.1s;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    visibility: hidden;
    z-index: 50;
    cursor: pointer;
  }
.overlayBackground.show {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.7); 
  }

.popUpContent{
  padding-left: 2%;
    width: 75%;
    height: 90%;
    display: flex;

}